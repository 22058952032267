<template>
  <div class="prod-container">
    <prod-nav
      @change="handleFilterChange"
      @addProd="handleAddProd"
      @search="handleSearch"
    ></prod-nav>
    <list :list="prodList" @succ="getProdList"></list>
    <edit-prod
      :show="show"
      :type="type"
      :modelId="modelId"
      @succ="handleAddSucc"
      @hide="handleShowHideDialog"
    />
  </div>
</template>

<script>
import ProdNav from "./components/Nav";
import List from "./components/List";
// import EditProd from "./components/EditProd";
import EditProd from "@/views/home/components/produce/components/EditProd";
import { mapState } from "vuex";
export default {
  name: "ProdList",
  components: { EditProd, ProdNav, List },
  props: {},
  data() {
    return {
      show: false,
      type: "add",
      modelName: "",
      modelId: null,
      prodList: [], //产品分类列表
      dialogVisible: false, //
      produceList: [],
    };
  },
  watch: {},
  computed: {},
  async created() {
    this.handleInit();
  },
  methods: {
    //初始化
    handleInit() {},
    //筛选
    async handleFilterChange(e) {
      this.modelId = e.modelId;
      this.getProdList();
    },
    //获取产品列表
    async getProdList() {
      const model_id = this.modelId;
      this.prodList = await this.$store.dispatch("getModelFindProd", {
        model_id,
      });
      this.allProd = this.prodList;
    },
    //搜索产品
    handleSearch(val) {
      if (val === "") {
        this.prodList = this.allProd;
        return;
      }
      this.prodList = this.allProd.reduce((prev, cur) => {
        if (cur.title.indexOf(val) > -1) {
          prev.push(cur);
        }
        return prev;
      }, []);
    },
    //新增产品
    handleAddProd(id) {
      this.model_id = id;
      this.show = !this.show;
      this.type = "add";
    },
    //新增产品成功
    handleAddSucc() {
      this.getProdList();
      this.handleShowHideDialog(false);
    },
    //展示隐藏弹窗
    handleShowHideDialog(bool = true) {
      this.dialogVisible = bool;
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  .el-row {
    display: flex;
    align-items: center;
    .el-dropdown {
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 100px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
  .el-table {
    margin-top: 20px;
  }
  .specBox {
    /deep/.el-dialog__body {
      padding: 10px 20px;
    }
    /deep/.el-input__inner {
      padding: 0;
    }
  }
  /deep/.el-pagination {
    text-align: left;
  }
  .supBox {
    .supply {
      display: flex;
      align-items: center;
      justify-content: space-around;
      /deep/.el-input__inner {
        width: 100px;
      }
      /deep/.el-input {
        width: 100px;
      }
    }
  }
}
.sele {
  /deep/ .success-row {
    color: #3333ee;
    font-size: 20px;
  }
}
.el-button {
  padding: 0 10px !important;
  height: 28px !important;
}
</style>

<template>
  <el-row :gutter="10" class="header-container">
    <el-col :span="4">
      <el-select filterable v-model="modelId" placeholder="请选择模型">
        <el-option
          v-for="item in modelList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="4">
      <el-input
        placeholder="请输入产品关键字"
        v-model="searchVal"
        class="input-with-select"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button>
      </el-input>
    </el-col>
    <el-col :span="3">
      <el-button type="primary" icon="el-icon-plus" @click="handleAddProd"
        >新增产品</el-button
      >
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ProdHeader",
  props: {},
  data() {
    return {
      initLock: false, //初始化锁
      searchVal: "",
      modelId: null,
      prodId: null,
      prodList: [],
      brandId: null,
    };
  },
  computed: {
    ...mapState(["modelList"]),
    //监听筛选参数
    filterParam() {
      return {
        modelId: this.modelId,
        prodId: this.prodId,
        brandId: this.brandId,
      };
    },
  },
  watch: {
    filterParam() {
      this.handleFilter();
    },
  },
  created() {
    this.handleInit();
  },
  methods: {
    //初始化
    async handleInit() {
      await this.$store.dispatch("getGoodsModels");
      this.modelId = this.modelList[0].id;
    },
    //搜索
    handleSearch() {
      this.$emit("search", this.searchVal);
    },
    //改变筛选
    handleFilter() {
      this.$tool.debounce(100).then(() => {
        const data = {
          modelId: this.modelId,
          modelList: this.modelList,
        };
        this.$emit("change", data);
      });
    },
    //新增
    handleAddProd() {
      this.$emit("addProd", this.modelId);
    },
    //刷新
    handleRefresh() {
      this.$emit("refresh");
    },
  },
};
</script>
<style lang="less" scoped>
.header-container {
  display: flex;
  align-items: center;
}
</style>

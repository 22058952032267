<template>
  <table-container :list="list">
    <el-table-column type="index" width="50" label="序号"> </el-table-column>
    <el-table-column prop="title" label="产品"> </el-table-column>
    <el-table-column prop="market_price" label="市场价"> </el-table-column>
    <el-table-column prop="model_name" label="模型"> </el-table-column>
    <el-table-column prop="name" label="操作">
      <template slot-scope="scope">
        <!-- <el-button
          type="primary"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(scope.$index, scope.row)"
          >编辑</el-button
        > -->
        <delete-btn :item="scope.row" @del="handleDelete"></delete-btn>
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    handleEdit() {},
    //删除
    async handleDelete(item) {
      const res = await this.$store.dispatch("delGoods", {
        goods_id: item.id,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>
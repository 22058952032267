<template>
  <ky-dialog :title="title" :show="show" :hide="hide">
    <el-form
      slot="dialog-content"
      :label-position="labelPosition"
      label-width="90px"
      :model="fromData"
      :rules="rules"
      ref="fromData"
    >
      <el-form-item label="产品名称:" prop="title">
        <el-col :span="14">
          <el-input v-model.number="fromData.title"></el-input
        ></el-col>
      </el-form-item>
      <el-form-item label="模型:" prop="model_id">
        <el-col :span="14">
          <el-select filterable v-model="model_id" placeholder="请选择模型">
            <template v-if="modelList">
              <el-option
                v-for="item of modelList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </template>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="材质:" prop="stuff_id">
        <el-col :span="14">
          <el-select
            filterable
            v-model="fromData.stuff_id"
            placeholder="请选择材质"
          >
            <template v-if="stuffList">
              <el-option
                v-for="item of stuffList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </template>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="市场价:" prop="market_price">
        <el-col :span="14">
          <el-input v-model="fromData.market_price"></el-input
        ></el-col>
      </el-form-item>
      <el-form-item label="额外成本:" prop="experienced_cost">
        <el-col :span="14">
          <el-input v-model="fromData.experienced_cost"></el-input
        ></el-col>
      </el-form-item>
      <el-form-item label="排序:">
        <el-col :span="14">
          <el-input type="number" v-model.number="fromData.sort"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        :label="'原材料' + (index + 1) + ':'"
        prop="materials"
        v-for="(item, index) in materialitemList"
        :key="index"
      >
        <div class="item-container">
          <el-select filterable v-model="item.material_id" placeholder="请选择">
            <template v-if="materialList">
              <el-option
                v-for="item of materialList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </template>
          </el-select>
          <div class="selectBox">
            <el-input-number
              v-model="item.num"
              controls-position="right"
              :min="1"
              size="mini"
            ></el-input-number>
          </div>
          <i
            class="el-icon-plus"
            style="color: green; font-weight: bold"
            v-if="index === materialitemList.length - 1"
            @click="addMetarial"
          ></i>
          <i
            class="el-icon-minus"
            style="color: red; font-weight: bold"
            v-else
            @click="delMetarial(index)"
          ></i>
        </div>
      </el-form-item>
    </el-form>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleTypeComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
import { mapState } from "vuex";
import rules from "@/util/rules";
export default {
  name: "EditProd",
  props: {
    modelId: Number,
    type: String,
    show: Boolean,
  },
  data() {
    return {
      title: "产品新增",
      hide: false,
      loading: false,
      labelPosition: "right",
      model_id: null,
      //表单数据
      fromData: {
        stuff_id: "",
        stuff_name: "",
        title: "",
        model_id: "",
        market_price: 1,
        experienced_cost: null,
        sort: 1,
        materials: "",
        main_attr_value: "",
      },
      materialList: [],
      mainAttrOptions: [],
      options: [],
      materialitemList: [{ material_id: "", num: 1 }],
      materialSelect: 1,
      rules: {
        stuff_id: [
          rules.noNullBlur("请输入材质名称"),
          rules.noNullChange("请输入材质名称"),
        ],
        model_id: [rules.numberBlur()],
        market_price: [rules.numberBlur()],
        materials: [rules.noNullBlur("原材料不能为空")],
      },
    };
  },
  computed: {
    ...mapState(["modelList", "stuffList"]),
  },
  watch: {
    modelId() {
      this.model_id = this.modelId;
    },
    model_id() {
      this.getMaterials();
    },
  },
  created() {
    this.handleInit();
  },
  methods: {
    //初始化
    async handleInit() {
      //型号列表
      await this.$store.dispatch("getGoodsModels");
      //材质列表
      await this.$store.dispatch("getAllStuff");
      //原材料列表
      this.getMaterials();
    },
    //获取原材料列表
    async getMaterials() {
      this.materialList = await this.$store.dispatch("getMaterials", {
        model_id: this.model_id,
      });
      this.materialId = this.materialList[0].id;
    },
    //上传图片
    getImage(e) {
      this.formLabelAlign.image = e.target.files[0];
    },
    //新增原材料选项
    addMetarial() {
      this.materialSelect += 1;
      this.materialitemList.push({ material_id: "", num: 1 });
    },
    //删除原材料
    delMetarial(index) {
      this.materialSelect -= 1;
      this.materialitemList.splice(index, 1);
    },
    //取消
    handleCancel() {
      this.hide = !this.hide;
    },
    //确定新增产品
    handleTypeComfirm() {
      const fromData = this.fromData;
      const data = {
        title: fromData.title,
        model_id: this.model_id,
        main_attr_value: fromData.stuff_id,
        market_price: fromData.market_price,
        experienced_cost: fromData.experienced_cost,
        sort: fromData.sort,
        materials: JSON.stringify(this.materialitemList),
      };
      this.$store.dispatch("createGoods", data).then(this.createGoodsThen);
    },
    createGoodsThen(res) {
      switch (res.code) {
        case 0:
          this.$emit("succ");
          this.$message({
            message: "新增成功",
            type: "success",
          });
          break;
        default:
          this.$message({
            message: res.msg,
            type: "error",
          });
          break;
      }
    },
  },
};
</script>
<style lang="less" scope>
.item-container {
  display: flex;
  align-items: center;
  .selectBox,
  .el-icon-plus,
  .el-icon-minus {
    margin-left: 10px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>

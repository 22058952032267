export default new class rules {
    noNullBlur(message = '不能为空') {
        return {
            required: true,
            message: message,
            trigger: "blur",
        }
    }
    noNullChange(message = '不能为空') {
        return {
            required: true,
            message: message,
            trigger: "change",
        }
    }
    numberBlur(message = '不能为空并且必须为数字') {
        return {
            type: "number",
            required: true,
            message,
            trigger: "blur",
        }
    }
}